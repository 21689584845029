import { reactive, ref } from 'vue';

const dealSummaryData = reactive({
    contractNumber: '',
    licenseeId: '',
    licenseeDetails: {
        licensee: '',
        licensor: '',
        description: '',
        contractNumber: '',
        startDate: '',
        endDate: '',
        contractType: '',
        contractStatus: ''
    },
    styleGuideDetails: {
        searchKeyword: '',
        tableData: {
            columns: [
                {
                    label: 'Style Guide',
                    key: 'styleGuide'
                }
            ],
            data: [],
            pageCount: 0,
            totalCount: 0,
            page: 1,
            limit: 5
        }
    },
    characterDetails: {
        searchKeyword: '',
        selectedStyleGuide: '',
        tableData: {
            columns: [
                {
                    label: 'Character Name',
                    key: 'character'
                },
                {
                    label: 'Start Date',
                    key: 'startDate'
                },
                {
                    label: 'End Date',
                    key: 'endDate'
                }
            ],
            data: [],
            pageCount: 0,
            totalCount: 0,
            page: 1,
            limit: 5
        }
    },
    articleDetails: {
        searchKeyword: '',
        tableData: {
            columns: [
                {
                    label: 'Article Category',
                    key: 'articleCategory'
                },
                {
                    label: 'Article Name',
                    key: 'article'
                },
                {
                    label: 'Article Note',
                    key: 'articleNote'
                },
                {
                    label: 'Article Path',
                    key: 'articleFullPath'
                },
                {
                    label: 'Start Date',
                    key: 'startDate'
                },
                {
                    label: 'End Date',
                    key: 'endDate'
                }
            ],
            data: [],
            pageCount: 0,
            totalCount: 0,
            page: 1,
            limit: 5
        }
    },
    channelDetails: {
        searchKeyword: '',
        tableData: {
            columns: [
                {
                    label: 'Channel',
                    key: 'channel'
                },
                {
                    label: 'Start Date',
                    key: 'startDate'
                },
                {
                    label: 'End Date',
                    key: 'endDate'
                }
            ],
            data: [],
            pageCount: 0,
            totalCount: 0,
            page: 1,
            limit: 5
        }
    },
    territoryDetails: {
        searchKeyword: '',
        tableData: {
            columns: [
                {
                    label: 'Region',
                    key: 'region'
                },
                {
                    label: 'Territory',
                    key: 'territory'
                },
                {
                    label: 'Territory Path',
                    key: 'territoryFullPath'
                },
                {
                    label: 'Start Date',
                    key: 'startDate'
                },
                {
                    label: 'End Date',
                    key: 'endDate'
                }
            ],
            data: [],
            pageCount: 0,
            totalCount: 0,
            page: 1,
            limit: 5
        }
    }
});

const isDealSummaryLoading = ref(false);

export default () => {
    return {
        dealSummaryData,
        isDealSummaryLoading
    };
};
