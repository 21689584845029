<template>
  <jump-to-section
    v-if="showBASubmissionForm"
    class="sticky top-0 z-10"
  />
  <div class="flex justify-center mt-4 px-6 pt-4 pb-9">
    <div
      v-if="!showBASubmissionForm"
      class="w-full max-w-3xl"
    >
      <div class="bg-custom-gray-10 px-3 py-2 leading-4 rounded-t-sm">
        <span class="font-semibold text-sm">
          Submissions Request Details
        </span>
      </div>
      <div
        v-if="!initCompleted"
        class="w-full py-32 text-center border border-custom-gray-8 border-t-0"
      >
        <base-svg
          class="h-4 w-4 mr-1 text-primary-red inline-block"
          src="icons/circleSpinner.svg"
          tag="span"
        />
        Loading ...
      </div>
      <form
        v-else
        class="relative py-7 px-32 border border-custom-gray-8 border-t-0"
      >
        <base-auto-complete-input
          v-model="submissionRequestData.licenseeName"
          :input-props="{
            type: 'text',
            containerClass: 'h-full w-full',
            placeholder: 'Name of Licensee'
          }"
          class="w-full mb-4 flex justify-between items-center"
          input-container-class="h-full w-full max-w-xs"
          label="Licensee"
          :options="licenseeNameOptions"
          :disabled="licenseeDisabled"
          :disable-suggestions="submissionRequestData.licenseeName.length < 2"
          @optionSelected="fetchLicenseeContractNumbers()"
        />
        <div
          v-if="noContractsFound"
          class="text-primary-red p-2 mb-4 text-center"
        >
          No contracts available for the licensee.
        </div>
        <base-select
          v-model="submissionRequestData.submissionType"
          class="w-full mb-4 flex justify-between items-center text-sm text-left"
          dropdown-btn-container-class="relative w-full max-w-xs"
          placeholder="-- SELECT --"
          label="Submission Type"
          item-text-property="name"
          item-value-property="submissionLookupKey"
          show-default-option
          :options="submissionTypeOptions"
          :disabled="!submissionRequestData.licenseeName || noContractsFound"
          @dropdownOpened="fetchSubmissionTypes()"
        />
        <base-select
          v-model="submissionRequestData.contractNumber"
          class="w-full mb-4 flex justify-between items-center text-sm text-left"
          dropdown-btn-container-class="relative w-full max-w-xs"
          placeholder="-- SELECT --"
          label="Contract Number"
          :options="contractNumberOptions"
          show-default-option
          :disabled="!submissionRequestData.licenseeName || noContractsFound"
        />
        <base-select
          v-model="submissionRequestData.sgObj"
          class="w-full mb-4 flex justify-between items-center text-sm text-left"
          dropdown-btn-container-class="relative w-full max-w-xs"
          placeholder="-- SELECT --"
          label="Style Guide"
          :options="styleGuideOptions"
          item-text-property="styleGuide"
          item-value-property="sgValue"
          show-default-option
          :disabled="!submissionRequestData.licenseeName || noContractsFound"
        />
        <div class="text-center">
          <base-button
            variant="btn-primary"
            class="mr-3"
            text="Create Form"
            :disabled="!isSubmissionRequestDataValid"
            :is-loading="isFormLoading"
            @click.stop.prevent="handleFormSubmit()"
          />
          <base-button
            variant="btn-link"
            text="Reset"
            type="button"
            :is-loading="isFormLoading"
            @click="handleReset()"
          />
        </div>
        <div class="absolute right-2 top-29">
          <base-button
            v-if="submissionRequestData.contractNumber"
            variant="btn-link"
            text="Deal Summary"
            type="button"
            @click="openDealSummary()"
          />
        </div>
      </form>
    </div>
    <brand-assurance-submission-form
      v-else
      class="w-full max-w-6xl"
      @discardSubmission="hideSubmissionForm()"
      @submissionCancelled="hideSubmissionForm()"
    />
  </div>
  <!-- deal summary modal -->
  <brand-assurance-deal-summary-modal
    v-model="isDealSummaryModalVisible"
    @hide="setDealSummaryModalVisibility(false)"
  />
</template>

<script>
import { defineAsyncComponent, reactive, ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import BaseSvg from '@/components/generic-components/BaseSvg.vue';
import useSubmissionForm from '@/components/brand-assurance-submission-form/submissionForm.js';
import { formatDate, getDistinctArray } from '@/helpers/util.js';
import useDealSummary from '@/components/brand-assurance-deal-summary/dealSummary';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { WARNING } from '@/constants/alerts';

export default {
    name: 'BANewSubmissionRequest',

    components: {
        BaseAutoCompleteInput: defineAsyncComponent(() => import('@/components/generic-components/BaseAutoCompleteInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        BrandAssuranceSubmissionForm: defineAsyncComponent(() => import('@/components/brand-assurance-submission-form/BrandAssuranceSubmissionForm.vue')),
        JumpToSection: defineAsyncComponent(() => import('@/components/brand-assurance-submission-form/JumpToSection.vue')),
        BrandAssuranceDealSummaryModal: defineAsyncComponent(() => import('@/components/brand-assurance-deal-summary/BrandAssuranceDealSummaryModal.vue')),
        BaseSvg
    },

    setup () {
        const store = useStore();
        const { updateSubmissionForm, submissionFormMode, resetSubmissionForm } = useSubmissionForm();
        const { dealSummaryData } = useDealSummary();
        const { notificationsStack } = useToastNotifications();

        const userLicensees = computed(() => store.getters['auth/getUserLicensees']);
        const selectedLicenseeName = computed(() => store.getters['auth/getSelectedLicensee']?.licenseeName || '');

        // submission request logic
        const submissionRequestData = reactive({
            licenseeName: '',
            submissionType: '',
            contractNumber: '',
            sgObj: undefined
        });
        const isSubmissionRequestDataValid = computed(() => {
            const submissionRequestValues = Object.values(submissionRequestData);
            return submissionRequestValues.filter(value => value).length === submissionRequestValues.length && licenseeNameOptions.value.includes(submissionRequestData.licenseeName);
        });

        // licensee name logic
        const licenseeNameOptions = computed(() => {
            // ....TO DISCUSS WITH ARSALAN....//
            // if (userLicensees?.value && userLicensees.value.length) {
            //     return userLicensees.value.map(l => l.licenseeName);
            // } else {
            const licensees = store.getters['baLicensee/getLicensees'];
            return licensees && licensees.length ? licensees.map(name => name.licensee) : [];
            // }
        });

        const licenseeNameOptionsLoading = ref(false);
        const fetchLicenseeNameOptions = async () => {
            try {
                if (licenseeNameOptionsLoading.value) {
                    return;
                }
                noContractsFound.value = false;
                licenseeNameOptionsLoading.value = true;

                await store.dispatch('baLicensee/fetchDrmLicenseesList', {
                    params: {
                        name: submissionRequestData.licenseeName
                    }
                });
            } catch (err) {
                console.error(err);
            } finally {
                licenseeNameOptionsLoading.value = false;
            }
        };

        watch(
            () => submissionRequestData.licenseeName,
            () => {
                if (submissionRequestData.licenseeName.length >= 2) fetchLicenseeNameOptions();
                submissionRequestData.contractNumber = '';
                store.dispatch('baLicensee/clearDrmLicenseeContractList');
            }
        );

        watch(
            () => submissionRequestData.contractNumber,
            () => {
                if (submissionRequestData.contractNumber) {
                    submissionRequestData.sgObj = undefined;
                    fetchStyleGuides();
                    dealSummaryData.contractNumber = submissionRequestData.contractNumber;
                }
            }
        );

        const licenseeId = computed(() => {
            const licensees = store.getters['baLicensee/getLicensees'];
            return (licensees?.find(el => submissionRequestData && submissionRequestData.licenseeName === el.licensee) || { licenseeId: '' }).licenseeId;
        });

        watch(
            () => licenseeId.value,
            () => {
                if (licenseeId.value) {
                    dealSummaryData.licenseeId = licenseeId.value;
                }
            }
        );

        const doesUserHaveAssociatedLicensees = computed(() => store.getters['auth/doesUserHaveAssociatedLicensees']);

        // submission types logic
        const submissionTypeOptions = computed(() => {
            let retValue = store.getters['baSubmissions/getSubmissionTypes'] || [];
            if (doesUserHaveAssociatedLicensees.value) retValue = retValue.filter(e => !e.name.includes('OPA'));
            return retValue;
        });
        const submissionTypesLoading = ref(false);
        const fetchSubmissionTypes = async () => {
            try {
                if (submissionTypeOptions.value.length > 0 || submissionTypesLoading.value) {
                    return;
                }
                submissionTypesLoading.value = true;
                await store.dispatch('baSubmissions/fetchSubmissionTypes', {
                    params: {
                        type: 'SUBMISSION'
                    }
                });
            } catch (err) {
                console.error(err);
            } finally {
                submissionTypesLoading.value = false;
            }
        };

        const noContractsFound = ref(false);
        // contract number logic
        const contractNumberOptions = computed(() => {
            const contractList = store.getters['baLicensee/getDrmLicenseeContractNumber'];
            return contractList ? contractList.map(id => id.contractNumber) : [];
        });
        const licenseeContractNumberOptionsLoading = ref(false);
        const fetchLicenseeContractNumbers = async () => {
            try {
                if (licenseeContractNumberOptionsLoading.value) {
                    return;
                }
                licenseeContractNumberOptionsLoading.value = true;
                await store.dispatch('baLicensee/fetchDrmLicenseesContractList', {
                    id: licenseeId.value,
                    params: {
                        contractStatus: 'EXECUTED BOOKED'
                    }
                });
                if (contractNumberOptions.value.length === 0) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: 'Unable to fetch Contract Details'
                    });
                    noContractsFound.value = true;
                }
            } catch (err) {
                console.error(err);
            } finally {
                licenseeContractNumberOptionsLoading.value = false;
            }
        };

        // style guide logic
        const styleGuideOptions = computed(() => {
            const styleGuides = store.getters['baStyleGuides/getDrmStyleGuidesList'];
            return styleGuides && styleGuides.data && styleGuides.data.length ? styleGuides.data.map(sg => ({ ...sg, sgValue: sg })) : [];
        });

        const styleGuideOptionsLoading = ref(false);
        const fetchStyleGuides = async () => {
            try {
                styleGuideOptionsLoading.value = true;
                await store.dispatch('baStyleGuides/fetchDrmStyleGuideContractList', {
                    params: {
                        contractNumber: submissionRequestData.contractNumber,
                        page: 1,
                        limit: 5000
                    }
                });
            } catch (err) {
                console.error(err);
            } finally {
                styleGuideOptionsLoading.value = false;
            }
        };

        // reset form logic
        const handleReset = async () => {
            noContractsFound.value = false;
            submissionRequestData.sgObj = undefined;
            submissionRequestData.licenseeName = '';
            submissionRequestData.submissionType = '';
            submissionRequestData.contractNumber = '';

            await store.dispatch('baSubmissions/resetAll');
            await store.dispatch('baStyleGuides/resetAll');
            await store.dispatch('baLicensee/clearDrmLicenseeContractList');
            await checkForUserLicensees();
        };

        const licenseeDisabled = ref(false);

        const checkForUserLicensees = async () => {
            if (selectedLicenseeName.value) {
                submissionRequestData.licenseeName = selectedLicenseeName.value;
                await fetchLicenseeNameOptions();
                await fetchLicenseeContractNumbers();
                submissionRequestData.contractNumber = '';
                licenseeDisabled.value = true;
            }
        };

        // submit form logic
        const showBASubmissionForm = ref(false);
        const isFormLoading = ref(false);
        const currentUser = computed(() => store.getters['auth/getUserInfo']);

        const submissionTypeName = computed(() => (submissionTypeOptions.value.find(submissionType => submissionType.submissionLookupKey === submissionRequestData.submissionType) || { name: null }).name);
        const handleFormSubmit = async () => {
            try {
                if (isFormLoading.value) {
                    return;
                }
                isFormLoading.value = true;
                await Promise.all([
                    fetchLicenseeDetails(),
                    generateNewSubmissionId(),
                    fetchSubmissionContractualSamplesDetails()
                ]);
                const submissionId = newSubmissionId.value;

                resetSubmissionForm();

                updateSubmissionForm({
                    submissionType: submissionRequestData.submissionType,
                    submissionTypeName: submissionTypeName.value,
                    contractNumber: submissionRequestData.contractNumber,
                    licenseeName: fetchedLicenseeDetails.value.licenseeName,
                    licenseeAddress: fetchedLicenseeDetails.value.licenseeAddress,
                    contactName: currentUser.value.name, // `${fetchedLicenseeDetails.value.contactFirstName} ${fetchedLicenseeDetails.value.contactLastName}`,
                    contactPhone: fetchedLicenseeDetails.value.contactPhoneNumber,
                    contactEmail: currentUser.value.email, // fetchedLicenseeDetails.value.contactEmailAddres,
                    licenseeId: licenseeId.value,
                    styleGuideName: submissionRequestData.sgObj.styleGuide,
                    styleGuideId: submissionRequestData.sgObj.styleGuideId,
                    submissionId
                });

                if (isSubmissionRequestDataValid.value) {
                    showBASubmissionForm.value = true;
                }
            } catch (err) {
                console.error(err);
            } finally {
                isFormLoading.value = false;
            }
        };

        const fetchSubmissionContractualSamplesDetails = async () => {
            try {
                await store.dispatch('baLicensee/fetchSubmissionsContractualSamples', {
                    contractNumber: dealSummaryData.contractNumber
                });
            } catch (err) {
                console.error(err);
            }
        };
        const fetchedLicenseeDetails = computed(() => store.getters['baLicensee/getDrmLicenseeDetails']);
        const fetchLicenseeDetails = async () => {
            try {
                await store.dispatch('baLicensee/fetchDrmLicenseeDetails', {
                    id: licenseeId.value
                });
            } catch (err) {
                console.error(err);
            }
        };
        const newSubmissionId = computed(() => {
            const newId = store.getters['baSubmissions/getNewSubmissionId'];
            return newId ? newId.identifier : '';
        });
        const generateNewSubmissionId = async () => {
            try {
                await store.dispatch('baSubmissions/generateNewSubmissionId', {
                    params: {
                        table: 'submission',
                        submissionType: submissionTypeName.value
                    }
                });
            } catch (err) {
                console.error(err);
            }
        };

        // deal summary logic
        const isDealSummaryModalVisible = ref(false);
        const setDealSummaryModalVisibility = (isModalVisible) => {
            if (!isModalVisible) submissionRequestData.sgObj = undefined;
            isDealSummaryModalVisible.value = isModalVisible;
        };
        const openDealSummary = () => {
            setDealSummaryModalVisibility(true);
        };

        // discard submission form logic
        const hideSubmissionForm = () => {
            showBASubmissionForm.value = false;
            handleReset();
        };

        const initCompleted = ref(false);

        onMounted(async () => {
            submissionFormMode.value = 'NEW';
            await handleReset();
            initCompleted.value = true;
        });

        return {
            submissionRequestData,
            noContractsFound,
            licenseeNameOptions,
            submissionTypeOptions,
            fetchSubmissionTypes,
            contractNumberOptions,
            fetchLicenseeContractNumbers,
            styleGuideOptions,
            fetchStyleGuides,
            handleReset,
            isFormLoading,
            handleFormSubmit,
            isSubmissionRequestDataValid,
            showBASubmissionForm,
            formatDate,
            getDistinctArray,
            // deal summary
            isDealSummaryModalVisible,
            setDealSummaryModalVisibility,
            openDealSummary,
            // discard submission form
            hideSubmissionForm,
            initCompleted,
            licenseeDisabled
        };
    }
};
</script>
